<template id="optasetup">
  <b-container fluid>
    <b-navbar type="dark" class="px-0" v-if="player">
      <b-navbar-nav :class="!auth.isAdmin() ? 'no-admin' : ''">
        <b-nav-item
          v-if="auth.isUserEnable('opta')"
          :to="'/scouting/view/player/' + player.id"
        >
          Player card
        </b-nav-item>
        <b-nav-item
          v-if="player.giocatore && auth.isUserEnable('player_edit')"
          :to="'/scouting/edit/player/' + player.giocatore.id"
        >
          Data edit
        </b-nav-item>
        <b-nav-item
          v-if="!player.giocatore && auth.isUserEnable('player_add')"
          :to="
            '/scouting/new/player/' + player.lastName + '/' + player.firstName
          "
        >
          Data edit
        </b-nav-item>
        <b-nav-item
          v-if="player.giocatore && auth.isUserEnable('player_eval')"
          :to="'/scouting/eval/player/' + player.giocatore.id"
        >
          Evaluation
        </b-nav-item>
        <b-nav-item
          v-if="
            player.giocatore &&
            player.giocatore.wyscout_id &&
            auth.isUserEnable('player_videotag')
          "
          :to="'/scouting/videotag/player/' + player.giocatore.id"
        >
          Videotag
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-btn size="sm" variant="info" title="Info" v-b-toggle.collapse
          ><b-icon icon="info-circle"></b-icon
        ></b-btn>
      </b-navbar-nav>
    </b-navbar>
    <b-card v-if="player">
      <b-row align-v="center">
        <b-col cols="1">
          <avatar
            :avatar="
              player.giocatore
                ? player.giocatore.avatar_url
                : '/assets/images/profile.png'
            "
          />
        </b-col>
        <b-col>
          <h5>
            {{ player.giocatore ? player.giocatore.cognome : player.lastName }}
            {{ player.giocatore ? player.giocatore.nome : player.firstName }}
          </h5>
          <p class="text-muted" v-if="player.ruolo">
            {{ player.ruolo.ruolo }}<br />
            Group
            {{ groups.find((item) => item.id == player.ruolo.gruppo).id }}:
            {{ groups.find((item) => item.id == player.ruolo.gruppo).name }}
          </p>
        </b-col>
        <b-col> </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col>
        <b-collapse id="collapse" class="mb-1">
          <b-row align-v="center" class="mt-2 mb-4">
            <b-col>
              <p>
                <strong>Micro basic</strong>: index calcolato sulle skills base
                (senza algoritmi) di tipo micro del gruppo ruolo di riferimento,
                senza normalizzare i valori tra 0 e 1, moltiplicate per i
                relativi pesi impostati sul gruppo ruolo (Micro/Weigth Player
                Basic Skill Setup)
              </p>
              <p>
                <strong>Macro basic</strong>: index calcolato su tutte le skills
                base (senza algoritmi), senza normalizzare i valori tra 0 e 1,
                moltiplicate per i relativi pesi impostati sul gruppo ruolo di
                riferimento (Micro/Weigth Player Basic Skill Setup)
              </p>
              <p>
                <strong>Micro raw</strong>: index calcolato sulle skills (con
                algoritmi) di tipo micro del gruppo ruolo di riferimento, senza
                normalizzare i valori tra 0 e 1, moltiplicate per i relativi
                pesi impostati sul gruppo ruolo (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Macro raw</strong>: index calcolato su tutte le skills
                (con algoritmi), senza normalizzare i valori tra 0 e 1,
                moltiplicate per i relativi pesi impostati sul gruppo ruolo di
                riferimento (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Micro</strong>: index calcolato sulle skills (con
                algoritmi) di tipo micro del gruppo ruolo di riferimento, con la
                normalizzare dei valori delle skilla tra 0 e 1 sui giocatori
                coinvolti nella partita, moltiplicate per i relativi pesi
                impostati sul gruppo ruolo (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Macro</strong>: index calcolato su tutte le skills (con
                algoritmi), con la normalizzare dei valori delle skilla tra 0 e
                1 sui giocatori coinvolti nella partita, moltiplicate per i
                relativi pesi impostati sul gruppo ruolo di riferimento
                (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Micro norm</strong>: index normalizzato tra 0 e 100 sui
                giocatori coinvolti nella partita, calcolato sulle skills (con
                algoritmi) di tipo micro del gruppo ruolo di riferimento, con la
                normalizzare dei valori delle skilla tra 0 e 1 sui giocatori
                coinvolti nella partita, moltiplicate per i relativi pesi
                impostati sul gruppo ruolo (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Macro norm</strong>: index normalizzato tra 0 e 100 sui
                giocatori coinvolti nella partita, calcolato su tutte le skills
                (con algoritmi), con la normalizzare dei valori delle skilla tra
                0 e 1 sui giocatori coinvolti nella partita, moltiplicate per i
                relativi pesi impostati sul gruppo ruolo di riferimento
                (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Micro group norm</strong>: index normalizzato tra 0 e
                100 sui giocatori dello stesso gruppo coinvolti nella partita,
                calcolato sulle skills (con algoritmi) di tipo micro del gruppo
                ruolo di riferimento, con la normalizzare dei valori delle
                skilla tra 0 e 1 sui giocatori coinvolti nella partita,
                moltiplicate per i relativi pesi impostati sul gruppo ruolo
                (Micro/Weigth Player Setup)
              </p>
              <p>
                <strong>Macro group norm</strong>: index normalizzato tra 0 e
                100 sui giocatori dello stesso gruppo coinvolti nella partita,
                calcolato su tutte le skills (con algoritmi), con la
                normalizzare dei valori delle skilla tra 0 e 1 sui giocatori
                coinvolti nella partita, moltiplicate per i relativi pesi
                impostati sul gruppo ruolo di riferimento (Micro/Weigth Player
                Setup)
              </p>
            </b-col>
          </b-row>
        </b-collapse>
      </b-col>
    </b-row>

    <b-table
      id="my-table"
      ref="table"
      sticky-header="600px"
      small
      striped
      dark
      responsive
      :fields="fields"
      :items="player_index_games"
      :tbody-tr-class="rowClass"
    >
      <template #cell(index)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ data.index + 1 }}
        </span>
      </template>
      <!--template #cell(player)="data">
  <span style="width: 200px; white-space: nowrap; overflow: hidden">
    <b-row>
      <b-col cols="1">
        <b-form-checkbox
        name="games_sel"
        v-model="index_game_ids"
        :value="data.item.id"
        >
      </b-form-checkbox>
    </b-col>
    <b-col>
      <span
      :style="
      index_game_ids.includes(data.item.id) ? 'color:#000000' : ''
      "
      >
      {{ data.value.player }}
    </span>
  </b-col>
</b-row>
</span>
</template-->
      <template #cell(game)="data">
        <b-row>
          <b-col cols="1">
            <b-form-checkbox
              name="games_sel"
              v-model="index_game_ids"
              :value="data.item.id"
            >
            </b-form-checkbox>
          </b-col>
          <b-col>
            <span
              :style="
                index_game_ids.includes(data.item.id) ? 'color:#000000' : ''
              "
            >
              <router-link
                :to="{
                  name: 'ScoutingPlayerIndexGame',
                  params: { id: $route.params.id, game_id: data.item.game_id },
                }"
                target="_blank"
                style="cursor: pointer"
              >
                {{ data.value.label }}
              </router-link>
            </span>
          </b-col>
        </b-row>
      </template>
      <template #cell(date)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ data.value | formatDate }}
        </span>
      </template>
      <template #cell(micro_index_basic)="data">
        <div
          :style="
            'color:#000000;background:' + data.item['micro_index_basic_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(macro_index_basic)="data">
        <div
          :style="
            'color:#000000;background:' + data.item['macro_index_basic_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(micro_index_raw)="data">
        <div
          :style="
            'color:#000000;background:' + data.item['micro_index_raw_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(macro_index_raw)="data">
        <div
          :style="
            'color:#000000;background:' + data.item['macro_index_raw_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(micro_index)="data">
        <div
          :style="'color:#000000;background:' + data.item['micro_index_color']"
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(macro_index)="data">
        <div
          :style="'color:#000000;background:' + data.item['macro_index_color']"
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(micro_index_norm)="data">
        <div
          :style="
            'color:#000000;background:' + data.item['micro_index_norm_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(macro_index_norm)="data">
        <div
          :style="
            'color:#000000;background:' + data.item['macro_index_norm_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(micro_index_group_norm)="data">
        <div
          :style="
            'color:#000000;background:' +
            data.item['micro_index_group_norm_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(macro_index_group_norm)="data">
        <div
          :style="
            'color:#000000;background:' +
            data.item['macro_index_group_norm_color']
          "
        >
          <strong>{{ Math.round(data.value * 100) / 100 }}</strong>
        </div>
      </template>
      <template #cell(group)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ data.value }}
        </span>
      </template>
      <template #cell(minutes)="data">
        <span
          :style="index_game_ids.includes(data.item.id) ? 'color:#000000' : ''"
        >
          {{ data.value }}
        </span>
      </template>
    </b-table>
  </b-container>
</template>

<script>
import moment from "moment";
import Auth from "@/lib/auth.js";
import Avatar from "@/components/Avatar.vue";
import loading from "@/assets/images/loading.gif";
export default {
  data: function () {
    return {
      ita: true,
      form: {
        player_id: this.$route.params.id,
      },
      groups: [
        { id: null, name: "-- Select --" },
        { id: 0, name: "PORTIERI" },
        { id: 1, name: "DIFENSORI CENTRALI" },
        { id: 2, name: "ESTERNI" },
        { id: 3, name: "CENTROCAMPISTI CENTRALI" },
        { id: 4, name: "INTERNI DI CENTROCAMPO" },
        { id: 5, name: "ATTACCANTI CENTRALI" },
        { id: 6, name: "ATTACCANTI ESTERNI" },
      ],
      minutes: [300, 600, 900],
      loading,
      waiting: false,
      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "game",
          sortable: true,
          label: "Game",
          thStyle: { "min-width": "100px" },
        },
        {
          key: "date",
          sortable: true,
          label: "Date",
          thStyle: { "min-width": "100px" },
        },
        {
          key: "micro_index_basic",
          sortable: true,
          label: "Micro basic",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "macro_index_basic",
          sortable: true,
          label: "Macro basic",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "micro_index_raw",
          sortable: true,
          label: "Micro raw",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "macro_index_raw",
          sortable: true,
          label: "Macro raw",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "micro_index",
          sortable: true,
          label: "Micro",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "macro_index",
          sortable: true,
          label: "Macro",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "micro_index_norm",
          sortable: true,
          label: "Micro norm",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "macro_index_norm",
          sortable: true,
          label: "Macro norm",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "micro_index_group_norm",
          sortable: true,
          label: "Micro group norm",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        {
          key: "macro_index_group_norm",
          sortable: true,
          label: "Macro group norm",
          class: "text-center",
          thStyle: { "min-width": "70px", "max-width": "70px" },
        },
        //  { key: "group", sortable: true, label: "Group", class: "text-center" },
        { key: "minutes", sortable: true, label: "Min", class: "text-center" },
      ],
      items: [],
      waiting_index: false,
      bg_opta: [
        "#00B386",
        "#2EC85B",
        "#7BDE3C",
        "#B8F100",
        "#E9F700",
        "#FFF300",
        "#FFDB00",
        "#FFB800",
        "#FF8900",
        "#FF0059",
        "#FF9999",
      ],
      filter: null,
      indeterminate: false,
      player_index_games: [],
      player: null,
      index_game_ids: [],
    };
  },

  components: {
    Avatar,
  },

  created: function () {
    this.getPlayer();
    this.getPlayerIndexGame();
  },

  watch: {},

  methods: {
    getPlayer() {
      this.$http
        .get("/scouting/view/player/" + this.$route.params.id + "/0")
        .then((response) => {
          this.player = response.data;
          if (!this.player.id) {
            this.$store.commit("msgPush", {
              msg: "Player not found!",
              status: 0,
            });
            this.$router.push({ name: "ScoutingPlayers" });
          }
        });
    },

    getPlayerIndexGame() {
      if (
        this.form.player_id == null ||
        this.form.player_id === "" ||
        isNaN(this.form.player_id)
      ) {
        alert("Insert a valid player ID!");
        return;
      }
      this.waiting_index = true;
      this.player_index_games = [];
      this.index_game_ids = [];

      this.$http.post("/player/index/game", this.form).then((response) => {
        this.player_index_games = response.data.player_index_games;

        var array_micro_basic = [];
        var array_macro_basic = [];
        var array_micro_raw = [];
        var array_macro_raw = [];
        var array_micro = [];
        var array_macro = [];
        var array_micro_norm = [];
        var array_macro_norm = [];
        var array_micro_group_norm = [];
        var array_macro_group_norm = [];

        for (var i in this.player_index_games) {
          var item = this.player_index_games[i];
          array_micro_basic.push(item.micro_index_basic);
          array_macro_basic.push(item.macro_index_basic);
          array_micro_raw.push(item.micro_index_raw);
          array_macro_raw.push(item.macro_index_raw);
          array_micro.push(item.micro_index);
          array_macro.push(item.macro_index);
          array_micro_norm.push(item.micro_index_norm);
          array_macro_norm.push(item.macro_index_norm);
          array_micro_group_norm.push(item.micro_index_group_norm);
          array_macro_group_norm.push(item.macro_index_group_norm);
        }

        array_micro_basic.sort(function (a, b) {
          return b - a;
        });
        array_macro_basic.sort(function (a, b) {
          return b - a;
        });
        array_micro_raw.sort(function (a, b) {
          return b - a;
        });
        array_macro_raw.sort(function (a, b) {
          return b - a;
        });
        array_micro.sort(function (a, b) {
          return b - a;
        });
        array_macro.sort(function (a, b) {
          return b - a;
        });
        array_micro_norm.sort(function (a, b) {
          return b - a;
        });
        array_macro_norm.sort(function (a, b) {
          return b - a;
        });
        array_micro_group_norm.sort(function (a, b) {
          return b - a;
        });
        array_macro_group_norm.sort(function (a, b) {
          return b - a;
        });

        for (var i in this.player_index_games) {
          var item = this.player_index_games[i];

          var color_micro_index_basic = this.getBgStatIndex(
            item.micro_index_basic,
            array_micro_basic
          );
          item["micro_index_basic_color"] = color_micro_index_basic;

          var color_macro_index_basic = this.getBgStatIndex(
            item.macro_index_basic,
            array_macro_basic
          );
          item["macro_index_basic_color"] = color_macro_index_basic;

          var color_micro_index_raw = this.getBgStatIndex(
            item.micro_index_raw,
            array_micro_raw
          );
          item["micro_index_raw_color"] = color_micro_index_raw;

          var color_macro_index_raw = this.getBgStatIndex(
            item.macro_index_raw,
            array_macro_raw
          );
          item["macro_index_raw_color"] = color_macro_index_raw;

          var color_micro_index = this.getBgStatIndex(
            item.micro_index,
            array_micro
          );
          item["micro_index_color"] = color_micro_index;

          var color_macro_index = this.getBgStatIndex(
            item.macro_index,
            array_macro
          );
          item["macro_index_color"] = color_macro_index;

          var color_micro_index_norm = this.getBgStatIndex(
            item.micro_index_norm,
            array_micro_norm
          );
          item["micro_index_norm_color"] = color_micro_index_norm;

          var color_macro_index_norm = this.getBgStatIndex(
            item.macro_index_norm,
            array_macro_norm
          );
          item["macro_index_norm_color"] = color_macro_index_norm;

          var color_micro_index_group_norm = this.getBgStatIndex(
            item.micro_index_group_norm,
            array_micro_group_norm
          );
          item["micro_index_group_norm_color"] = color_micro_index_group_norm;

          var color_macro_index_group_norm = this.getBgStatIndex(
            item.macro_index_group_norm,
            array_macro_group_norm
          );
          item["macro_index_group_norm_color"] = color_macro_index_group_norm;
        }

        this.waiting_index = false;
      });
    },

    getBgStatIndex(value, array_range) {
      if (!isNaN(value)) {
        value = parseFloat(value);
        var count_array = array_range ? array_range.length : 0;
        var interval = count_array > 10 ? Math.floor(count_array / 10) : 1;
        var index = 0;
        for (var i = 0; i <= count_array - 1; i += interval) {
          var to = i + interval;
          if (i + interval > count_array - 1) {
            to = count_array - 1;
          }
          if (index == 0 && value > parseFloat(array_range[i])) {
            return this.bg_opta[index];
          }
          if (index == 9 && value < parseFloat(array_range[to])) {
            return this.bg_opta[index];
          }
          if (
            value <= parseFloat(array_range[i]) &&
            value >= parseFloat(array_range[to])
          ) {
            return this.bg_opta[index > 9 ? 9 : index];
          }
          index++;
        }

        return this.bg_opta[9];
      }
      return "#FFFFFF";
    },

    onFiltered(filteredItems) {},

    rowClass(item, type) {
      if (!item || type !== "row") {
        return "";
      }
      if (this.index_game_ids.includes(item.id)) {
        return "table-success";
      }
      return "";
    },
  },

  computed: {
    auth: function () {
      return Auth;
    },
  },

  filters: {
    formatDate: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("DD/MM/YYYY");
      }
      return "";
    },
  },
};
</script>
